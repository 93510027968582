import { useAppState } from "../../State"
import { getTotalByTariff } from "../buy";

export const TotalListItem = ({tariff}) => {
    const [state] = useAppState();

    return (
        <>

            {
                state.tariffs[tariff.id] !== undefined
                    ?
                    <>
                        <span className="price_list-value">{ getTotalByTariff(state.tariffs, tariff) }</span>
                        <div className="price_list-input-block">
                            <span className="price_list-input input-border">{state.tariffs[tariff.id]}</span>
                        </div>
                    </>
                    :
                    <>
                        <span className="price_list-value">–</span>
                    </>
            }
        </>
    )
}