import { Controller, useForm } from "react-hook-form";
import { useAppState } from "../State"
import { PhoneInput, EmailInput } from "../components/contact";
import { CheckFormSubmit } from "../components/buy/CheckFormSubmit";

export const Contact = () => {
    const [state, setState] = useAppState();
    
    const {
        control,
        handleSubmit,
        watch
    } = useForm({
        defaultValues: {
            phone: state.phone,
            email: state.email
        }, mode: "onChange"
    });

    const saveData = (data) => {
        setState({ ...state, ...data, step: 2 });
    };

    const checkFormSubmitHandler = () => {
        return CheckFormSubmit('phone', watch()) || CheckFormSubmit('email', watch());
    }

    return (
        <div className="contact_step">
            <form className="contact_step-form" onSubmit={handleSubmit(saveData)}>
                <p className="contact_step-title">Укажите номер телефона<br />для получения ссылки на билет</p>
                <Controller
                    control={control}
                    name="phone"
                    defaultValue={''}
                    render={
                        ({ field: { onChange, value } }) =>
                            <PhoneInput onChange={onChange} value={value} />
                    } />
                <Controller
                    control={control}
                    name="email"
                    defaultValue={''}
                    render={
                        ({ field: { onChange, value } }) =>
                            <EmailInput onChange={onChange} value={value} />
                    } />
                <p className="contact_step-caption">Указывайте корректный телефон. На него придет СМС с билетом и чеком</p>
                <div className="contact_step-footer">
                    <button className="step-button" onClick={() => setState({ ...state, step: 0 })}>Назад</button>
                    <button disabled={checkFormSubmitHandler()} className="step-button" type="submit">Продолжить</button>
                </div>
            </form>
        </div>
    );
}