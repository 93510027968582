import { useEffect, useState } from 'react';
import './assets/css/App.css';
import { Buy, Info } from './screens';
import { AppProvider } from './State';
import { Loader } from './components/common/Loader';

const App = () => {
  const [state, setState] = useState({
    orderCode: '',
    loading: true,
  });

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    setState({
      orderCode: urlParams.get('orderCode'),
      loading: false,
    });
  }, []);
  
  return (
    <div className='app-wrapper'>
      <AppProvider>
        <Loader />
        {state.orderCode
          ?
          <Info orderCode={ state.orderCode } />
          :
          <Buy />
        }
      </AppProvider>
    </div>
  );
};

export default App;
