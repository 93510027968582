import { useEffect, useState } from "react";
import { GFetch } from "../GFetch";
import { ScreenWrapper } from "../components/common"
import QRCode from "react-qr-code";

export const Info = ({ orderCode }) => {
    const [saleInfo, setSaleInfo] = useState();
    const [cancelSaleAsk, setCancelSaleAsk] = useState(false);
    const [cancelSaleResult, setCancelSaleResult] = useState();

    const getSaleDate = () => {
        var date = new Date(saleInfo.saleDate);

        var options = {
            month: 'long',
            day: 'numeric',
            weekday: 'short',
        };

        return date.toLocaleDateString('ru', options);
    }

    useEffect(() => {
        getSaleInfo();
    }, []);

    const cancelSaleHandler = () => {
        GFetch('POST', 'sale/' + orderCode + '/cancel', {})
            .then((res) => {
                setCancelSaleResult(res.success);

                if (res.success) {
                    getSaleInfo();
                }
            })
    }

    const getSaleInfo = () => {
        GFetch('POST', 'sale/' + orderCode, {})
            .then((res) => {
                setSaleInfo(res)
            })
    }

    return (
        <ScreenWrapper>
            <div className="sale_info-block">
                {saleInfo &&
                    <>
                        {saleInfo.status != 'canceled'
                            ?
                            <>
                                {cancelSaleAsk ?
                                    <>
                                        <div className="order_cancel-block">
                                            <span className="order_cancel-title">Отмена заказа</span>
                                            <div>
                                                <span className="order_cancel-text">Вы уверены что хотите отменить заказ {saleInfo.number} ?</span>
                                            </div>
                                            {cancelSaleResult == false &&
                                                <><p>Не удалось</p></>
                                            }
                                            <div className="order_cancel_btn-block">
                                                <span className="order_cancel-btn order_cancel-btn_yes" onClick={() => cancelSaleHandler(false)}>Отменить</span>
                                                <span className="order_cancel-btn order_cancel-btn_no" onClick={() => setCancelSaleAsk(false)}>Я передумал</span>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <span className="sale_info_title">Заказ {saleInfo.number}<br />Ждём вас в {getSaleDate()}</span>
                                        {saleInfo.tariffs &&
                                            <>
                                                <div className="qr_list">
                                                    {Object.values(saleInfo.tariffs).map((tariff, index) => {
                                                        return (
                                                            <div className="qr_list-item" key={index}>
                                                                <span className="qr_list-item-name">{tariff.name}</span>
                                                                <QRCode
                                                                    size={155}
                                                                    value={tariff.barcode}
                                                                    viewBox={`0 0 155 155`}
                                                                />
                                                                <span>
                                                                    {tariff.barcode}
                                                                </span>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </>
                                        }
                                        <div className="sale_info_faq-block">
                                            <ul className="sale_info_faq-list">
                                                <li><span>🚘</span> Приехать к нам в комплекс по адресу Свердловский 51а</li>
                                                <li><span>✏️</span> Заполнить анкету перед кассой или <a href="https://anketa.letotermy.ru/">онлайн</a></li>
                                                <li><span>🎟</span> Пройти с электронным билетом и анкетой на кассу</li>
                                                <li><span>☀️</span> Наслаждаться посещением</li>
                                            </ul>
                                        </div>
                                        <span className="sale_info_phone">Телефон: <b><a href="tel:83512400024">8 (351) 24-000-24</a></b></span>
                                        {
                                            saleInfo.customer.email &&
                                            <div className="sale_info_email">Для продления парковки, используйте QR-код из чека “Платформа ОФД”, отправленный на почту <b>{saleInfo.customer.email}</b></div>
                                        }
                                        <span className="sale_info_helper">
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M9.25 9.25L9.29149 9.22926C9.86461 8.9427 10.5099 9.46034 10.3545 10.082L9.64549 12.918C9.49009 13.5397 10.1354 14.0573 10.7085 13.7707L10.75 13.75M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10ZM10 6.25H10.0075V6.2575H10V6.25Z" stroke="#112867" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                            <span>
                                                Проход осуществляется в порядке живой очереди.
                                            </span>
                                        </span>
                                        <span className="order_cancel_action" onClick={() => setCancelSaleAsk(true)}>Отменить заказ</span>
                                    </>
                                }
                            </>
                            :
                            <>
                                <div className="order_cancel-block">
                                    <span className="order_cancel-title">Отмена заказа</span>
                                    <div>
                                        <span className="order_cancel-text">
                                            Заказ {saleInfo.number} успешно отменён!<br></br>
                                            Срок возврата средств на карту может составлять до 10 банковских дней.
                                        </span>
                                        <span className="order_cancel-text">
                                            Телефон поддержки: +7 351 250-52-50
                                        </span>
                                    </div>
                                    <div className="order_cancel_btn-block">
                                        <a className="order_cancel-btn order_cancel-btn_no" href="/">Спасибо</a>
                                    </div>
                                </div>
                            </>
                        }
                    </>
                }
            </div>
        </ScreenWrapper>
    )
}