import { GFetch } from "../GFetch";
import { useAppState } from "../State"
import { masked } from "../components/contact";
import { TariffTypeList } from "../components/price";
import { TotalCost, TotalListItem } from "../components/total";

export const Total = ({ tariffList }) => {
    const [state, setState] = useAppState();

    const getSaleDate = () => {
        var date = new Date(state.saleDate);

        var options = {
            month: 'long',
            day: 'numeric',
            weekday: 'long',
        };

        return date.toLocaleDateString('ru', options);
    }

    const createSale = () => {
        setState({ ...state, loading: true });

        GFetch('POST', 'sale/create', state)
            .then((res) => {
                window.location.href = res.formUrl;
            })
    };

    return (
        <div className="total_step">
            <span className="total__list_date">{getSaleDate()}</span>

            <div className="price_list-head">
                <TariffTypeList tariffTypeList={tariffList.tariffTypeList} />
            </div>
            <div className="price_list">
                <div className="price_list-form">
                    <div className="price_list-body">
                        {
                            Object.keys(tariffList.tariffGroupList).map((tariffGroupId, index) => {
                                return <div key={index} className="price_list-body-item price_list-body-row">
                                    <div className="price_list-column">
                                        <span className="price_list-value">{tariffList.tariffGroupList[tariffGroupId].name} </span>
                                    </div>
                                    {
                                        Object.keys(tariffList.tariffTypeList).map((tariffTypeId, index) => {
                                            return <div className="price_list-column">
                                                <TotalListItem tariff={ tariffList.tariffPriceList[tariffGroupId][tariffTypeId] } />
                                            </div>
                                        })
                                    }
                                </div>
                            })}
                    </div>
                </div>
            </div>
            <TotalCost tariffPriceList={ tariffList.tariffPriceList } />
            <div className="total_step_phone-text">
                <span>Вам будет отправлено СМС с ссылкой на билет,</span><br /><span>на номер <b>{masked('phone', state.phone) }</b></span>
            </div>
            <div className="contact_step-footer">
                <button className="step-button" onClick={() => setState({ ...state, step: 0 })}>Назад</button>
                <a className="step-button" onClick={ () => createSale() }>Оплатить</a>
            </div>
        </div>
    )
}