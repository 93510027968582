import { TariffPrice } from "./TariffPrice";

export const TariffPriceList = (props) => {
    return (
        <>
            { props.tariffPriceList != false && 
                Object.keys(props.tariffGroupList).map((tariffGroupId, index) => {
                    return <div key={index} className="price_list-body-item price_list-body-row">
                        <div className="price_list-column">
                            <span className="price_list-value">{ props.tariffGroupList[tariffGroupId].name } </span>
                        </div>
                        {
                            Object.keys(props.tariffTypeList).map((tariffTypeId, index) => {
                                return <TariffPrice key={ index } control={props.control} tariffPrice={props.tariffPriceList[tariffGroupId][tariffTypeId]} />
                            })
                        }
                    </div>
                })
            }
        </>
    );
}