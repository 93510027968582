import React, { createContext, useContext, useState } from "react";

export const AppStateContext = createContext();

export function AppProvider({ children }) {
  const todayDate = (new Date()).toISOString().split('T')[0];

  const value = useState({
    loading: false,
    freeDateSale: 0,
    calendarSelected: false,
    saleDate: todayDate,
    todayDate: todayDate,
    step: 0 // устанавливаем loading в false по умолчанию
  });
  
  return (
    <AppStateContext.Provider value={value}>
      {children}
    </AppStateContext.Provider>
  );
}

export function useAppState() {
  const context = useContext(AppStateContext);

  if (!context) {
    throw new Error("useAppState must be used within the AppProvider");
  }
  
  return context;
}