import { useEffect, useState } from "react";
import { GFetch } from "../../GFetch";
import { Day } from "./Day";
import { useAppState } from "../../State";
import { DayListCalendar } from "../buy";

export const DayList = (props) => {
    const [state, setState] = useAppState();
    const [days, setDays] = useState();
    
    useEffect(() => {
        GFetch('POST', 'days', [])
            .then((res) => {
                if (res) {
                    if (state.saleDate === undefined) {
                        setState({ ...state, saleDate: res[0].date });
                    }

                    setDays(res);
                }
            })
    }, []);

    return (
        <>
            {days
                ?
                <div className="days_list">
                    {
                        days.map((item, index) => {
                            return (
                                <Day key={index} date={item.date} price={item.price} />
                            )
                        })

                    }
                    <DayListCalendar />
                </div>
                :
                <></>
            }
        </>
    )
}