import { useEffect, useState } from 'react';
import { useAppState } from '../State';
import { Contact, Price, Total } from '../steps';
import { ScreenWrapper } from '../components/common';
import { BuyStepNav } from '../components/buy';
import { GFetch } from '../GFetch';

export const Buy = () => {
    const [state, setState] = useAppState();
    const [tariffList, setTariffList] = useState();
    
    useEffect(() => {
        GFetch('POST', 'tariff', { saleDate: state.saleDate, freeDate: state.freeDateSale })
            .then((res) => {
                setTariffList(res);
            })

        setState({ ...state, loading: false });
    }, [state.saleDate, state.freeDateSale]);

    const Steps = [
        <Price tariffList={tariffList} />, <Contact />, <Total tariffList={tariffList} />
    ];

    return (
        <ScreenWrapper>
            <BuyStepNav />
            {Steps[state.step]}
        </ScreenWrapper>
    );
}