import { useAppState } from "../../State";

export const Day = (props) => {
    const [state, setState] = useAppState();
    const date = new Date(props.date);
    const dateNow = new Date();
    
    const dayHandler = (date) => {
        const dayNames = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];
        
        let tomorrowDate = new Date();
        tomorrowDate.setDate(dateNow.getDate() + 1)

        switch (date.toDateString()) {
            case dateNow.toDateString():
                return 'Сегодня';
            case tomorrowDate.toDateString():
                return 'Завтра';
            default:
                return dayNames[date.getDay()];
        }
        
    }

    const dateHandler = (date) => {
        let options = {
            day: 'numeric',
            month: 'numeric'
        }

        return date.toLocaleString("ru", options);
    }

    const priceHandler = (price) => {
        return Math.round(price / 100) + '₽';
    }

    const selectDate = () => {
        setState({ ...state, saleDate: props.date });
    }

    return (
        <div className={"days_list-item day" + (props.date === state.saleDate ? ' active' : '' )} onClick={() => selectDate()}>
            <span className="day-name">{ dayHandler(date) }</span>
            <span className="day-date">{ dateHandler(date) }</span>
            <span className="day-price">от { priceHandler(props.price) }</span>
        </div>
    )
}
