export const CheckFormSubmit = (type, watch) => {
    let disabled = true;

    switch (type) {
        case 'price':
            if (watch.tariffs !== undefined) {
                watch.tariffs.forEach((element) => {
                    if (element > 0) {
                        disabled = false;
                    }
                })

            }

            break;

        case 'phone':
            if (/^\d{11}$/.test(watch.phone)) {
                disabled = false;
            }

            break;

        case 'email':
            if (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(watch.email)) {
                disabled = false;
            }

            break

        default:
            disabled = true;

            break;
    }

    return disabled;
}