import { useState } from "react";

export const TariffPriceInput = ({onChange, value}) => {
    const [inputValue, setInputValue] = useState(value);

    const handleOnChange = (val) => {
        val = Math.max(0, parseInt(val) || 0)
        
        setInputValue(val)
        onChange(val);
    }

    return (
        <>
            <button disabled={inputValue <= 0 ? true : false} className="price_list-input-action" type="button" onClick={() => handleOnChange(inputValue - 1)}>-</button>
            <input min={0} max={10} maxLength={2} className="price_list-input input-border" type="number" value={ inputValue } onChange={(e) => handleOnChange(e.target.value)}/>
            <button disabled={inputValue >= 10 ? true : false} className="price_list-input-action" type="button" onClick={() => handleOnChange(inputValue + 1)}>+</button>
        </>
    );
}