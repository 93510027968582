import { useAppState } from "../../State";
import { getTotal } from "../buy";

export const TotalCost = ({ tariffPriceList }) => {
    const [state] = useAppState();


    
    return (
        <div className="total_step_total">
            <span>Общий итог</span>
            <span>{ getTotal(state.tariffs, tariffPriceList) }</span>
        </div>
    );
}