import { Controller } from "react-hook-form";
import { TariffPriceInput } from "./TariffPriceInput";

export const TariffPrice = ({ control, tariffPrice }) => {
    const priceHandler = (price) => {
        return Math.round(price / 100) + ' ₽';
    }

    return (
        <div className="price_list-column">
            <span className="price_list-value">{priceHandler(tariffPrice.price)}</span>
            <div className="price_list-input-block">
                <Controller
                    control={control}
                    name={`tariffs.${tariffPrice.id}.`}
                    defaultValue={0}
                    render={
                        ({ field: { onChange, value } }) =>
                            <TariffPriceInput onChange={ onChange } value={value} />
                    } />
            </div>
        </div>
    );
}