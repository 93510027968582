import { getTotal, getTotalByType } from "../buy";

export const TariffListTotal = ({ formWatch, ...props }) => {
    return (
        <>
            {
                formWatch.tariffs ?
                    <div className="price_list_total-block">
                        <div className="price_list_total_type-block">
                            {
                            Object.keys(props.tariffTypeList).map((tariffTypeId, index) => {
                                return <span key={index}>{ props.tariffTypeList[tariffTypeId].name}: <span></span>{ getTotalByType(formWatch.tariffs, props.tariffPriceList, tariffTypeId) }</span>
                            })}
                        </div>
                        <span className="price_list_total">
                            { getTotal(formWatch.tariffs, props.tariffPriceList) }
                        </span>
                    </div>
                    :
                    <></>
            }
        </>
    );
}