import { useState } from "react";
import { masked, unMasked } from "./InputMask";

export const EmailInput = ({onChange, value}) => {
    const [inputValue, setInputValue] = useState(value);
    
    const handleOnChange = (val) => {
        setInputValue(val)
        onChange(val);
    }

    return (
        <input className="input-border input_contact" onChange={ (e) => handleOnChange(unMasked('email', e.target.value)) } type="text" placeholder="Email для чека" value={ masked('email', inputValue) } />
    )
}