export const TariffTypeList = (props) => {
    return (
        <>
            <div className="price_list-head-item price_list-column"><p className="price_list-head-title">Тарифы<span>включено посещение банного комплекса</span></p></div>
            {
                props.tariffTypeList
                    ?
                    <>
                        {Object.values(props.tariffTypeList).map(item => {
                            return (
                                <div key={item.id} className="price_list-head-item price_list-column"><p className="price_list-head-title">{ item.name }<br /><span>{ item.description }</span></p></div>
                            );
                        })}
                    </>
                    :
                    <></>
            }
        </>
    )
}