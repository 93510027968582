import Calendar from "react-calendar";
import { useAppState } from "../../State"
import { CalendarIcon, CalendarIconInversed } from "../icons";
import { useState } from "react";

export const DayListCalendar = () => {
	const [state, setState] = useAppState();
	const [showCalendar, setShowCalendar] = useState(false);

    const date = new Date(state.saleDate);
    
    const dayHandler = (date) => {
        const dayNames = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];
        
		return dayNames[date.getDay()];
	}

    const dateHandler = (date) => {
        let options = {
            day: 'numeric',
            month: 'numeric'
        }

        return date.toLocaleString("ru", options);
    }

	const onChangeHandler = (date) => {
		let convertedDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().split('T')[0];
		setShowCalendar(false);
		setState({ ...state, saleDate: convertedDate, calendarSelected: true });

	}

	return (
		<div className={"days_list-item " + (state.calendarSelected ? ' active' : '')}>
			<button onClick={() => setShowCalendar(true)}>
				{
					state.calendarSelected
						?
						<>
							<span className="day-name">{dayHandler(date)}</span>
							<span className="day-date">{dateHandler(date)}</span>
							<CalendarIconInversed />
						</>
						:
						<>
							<span>Выбрать дату</span>
							<CalendarIcon />
						</>
				}
			</button>
			{showCalendar &&
				<div className="calendar">
					<div className="days_list_calendar-closure" onClick={() => setShowCalendar(false)}>
					</div>
					<div className="days_list_calendar-block">
						<Calendar locale={"ru-RU"} minDate={new Date()} showWeekNumbers={false} onClickDay={(e) => onChangeHandler(e)} next2Label={null} prev2Label={null} minDetail={"month"} />
					</div>
				</div>
			}
		</div>
	)
}