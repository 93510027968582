export async function GFetch(type, path, sendData) {
    const serverUrl = "https://booking.letotermy.ru/api/";
    
    const headers = {
        'Accept' : 'application/json',
    };

    let options = {
        method: 'POST',
        body: JSON.stringify(sendData),
        headers
    };

    const response = await fetch(serverUrl + path, options);

    if (!response.status) {
        throw new Error('Ошибка');
    }

    return await response.json();
}