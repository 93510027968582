
export const getTotalByType = (tariffs, tariffPriceList, typeId) => {
    let total = 0;

    Object.keys(tariffPriceList).map((tariffGroupId, index) => {
        let tariffId = tariffPriceList[tariffGroupId][typeId].id;

        if (tariffs[tariffId] != undefined) {
            total = total + (parseInt(tariffs[tariffId]) * parseInt(tariffPriceList[tariffGroupId][typeId].price));
        }

    })

    return Math.round(total / 100) + ' ₽';
}

export const getTotal = (tariffs, tariffPriceList) => {
    let total = 0;

    Object.values(tariffPriceList).map((tariffGroup, index) => {
        Object.values(tariffGroup).map((tariff, index) => {
            if (tariffs[tariff.id] != undefined) {
                total = total + (parseInt(tariffs[tariff.id]) * parseInt(tariff.price));
            }
        })
    })

    return Math.round(total / 100) + ' ₽';
}

export const getTotalByTariff = (tariffs, tariff) => {
    let total = parseInt(tariff.price) * parseInt(tariffs[tariff.id]);

    return Math.round(total / 100) + ' ₽';
}