export const masked = (field, val) => {
    if (val !== undefined) {
    let maskedText = val;
    switch (field) {
        case 'phone':
            // Оставляем только цифры
            let cleanNumber = val.replace(/[^\d]/g, '');

            if (cleanNumber.charAt(0) === '8') {
                cleanNumber = cleanNumber.replace('8', '7');
            } else if (cleanNumber.charAt(0) !== '7') {
                cleanNumber = '7' + cleanNumber.slice(1);
            }

            maskedText = cleanNumber.replace(/(\d{1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/,
                (match, p1, p2, p3, p4, p5) => {
                    let result = '';
                    if (p1) result += `+${p1}`;
                    if (p2) result += ` (${p2}`;
                    if (p3) result += `) ${p3}`;
                    if (p4) result += `-${p4}`;
                    if (p5) result += `-${p5}`;
                    return result;
                });

            break;
        case 'name':
            maskedText = val.replace(/[^\sа-яА-Я]/g, '');
            break;
        case 'email':
            maskedText = val.trim();
            break;
        case 'code':
            maskedText = val.replace(/[^\d]/g, '');
            break;
        case 'birthday':
            maskedText = new Date(val);

            maskedText = maskedText.toLocaleDateString('ru-RU', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
            });
            
            break;
        case "promocode":
            maskedText = val.replace(/[^a-zA-Z0-9]/g, '').toUpperCase();
            break
        default:
            break;
    }

    return maskedText; 
    }
}

export const unMasked = (field, val) => {
    let unMaskedText = val;

    switch (field) {
        case 'phone':
            unMaskedText = val.replace(/[^\d]/g, '').slice(0, 11);
            break;
        case 'name':
            break;
        case 'email':
            break;
        case 'code':
            unMaskedText = val.replace(/[^\d]/g, '');
            break;
        case 'birthday':
            unMaskedText = new Date(val);

            unMaskedText = unMaskedText.toLocaleDateString('ru-RU', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
            });
            
            break;
        default:
            break;
    }

    return unMaskedText;
}